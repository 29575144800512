/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaCog, FaSearch } from 'react-icons/fa';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { range, sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import TeamDropdown from '../../components/TeamDropdown';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Route } from '../../types/routes.d';
import TeamsMemberSearchModal from '../../components/search/TeamsMemberSearchModal';
import { useState } from 'react';
import useUserContext from '../../contexts/UserContext';
import { Roles } from '../../types/roles.d';

export const GET_MEMBERS = gql`
  query GetTeamMembers($slug: String) {
    members(filters: { teams: { slug: { eq: $slug } } }, pagination: { pageSize: 100 }) {
      data {
        id
        attributes {
          full_name
          badges {
            data {
              id
              attributes {
                name
                badge_icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                team {
                  data {
                    id
                    attributes {
                      slug
                    }
                  }
                }
              }
            }
          }
          team_roles {
            data {
              attributes {
                name
                team {
                  data {
                    attributes {
                      slug
                      name
                    }
                  }
                }
                color {
                  data {
                    attributes {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type GetMembersType = {
  members: {
    data: {
      id: string;
      attributes: {
        full_name: string;
        badges: {
          data: {
            id: string;
            attributes: {
              name: string;
              badge_icon: {
                data: {
                  attributes: {
                    url: string;
                  };
                };
              };
              team: {
                data: {
                  id: string;
                  attributes: {
                    slug: string;
                  };
                };
              };
            };
          }[];
        };
        team_roles: {
          data: {
            attributes: {
              name: string;
              team: {
                data: {
                  attributes: {
                    slug: string;
                    name: string;
                  };
                };
              };
              color: {
                data: {
                  attributes: {
                    value: string;
                  };
                };
              };
            };
          }[];
        };
      };
    }[];
  };
};

export const GET_ROLES = gql`
  query GetTeamRoles($slug: String) {
    teamRoles(sort: ["organigram_level_position:asc"], filters: { team: { slug: { eq: $slug } } }) {
      data {
        id
        attributes {
          name
          description
          color {
            data {
              attributes {
                value
              }
            }
          }
          members {
            data {
              id
              attributes {
                full_name
              }
            }
          }
        }
      }
    }
  }
`;

type GetRolesType = {
  teamRoles: {
    data: {
      id: string;
      attributes: {
        name: string;
        description: string;
        color: {
          data: {
            attributes: {
              value: string;
            };
          };
        };
        members: {
          data: [
            {
              id: string;
              attributes: {
                full_name: string;
              };
            },
          ];
        };
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const BadgeIcon = styled.img`
  ${({ theme }) => `
  width: ${theme.getValue(36)}px;
  height: ${theme.getValue(36)}px;
  object-fit: contain;
  object-position: center;
  `}
`;

const Circle = styled.div<{ color: string }>`
  display: inline-block;
  ${({ theme, color }) => `
    width: ${theme.getValue(16)}px;
    border-radius: ${theme.getValue(16)}px;
    height: ${theme.getValue(16)}px;
    margin-right: ${theme.getValue(16)}px;
    background: ${color};
  `}
`;

const RoleName = styled.span`
  opacity: 0.7;
  ${({ theme }) => `
    font-size: ${theme.getValue(20)}px;
  `}
`;

const MemberName = styled.div`
  font-weight: 600;
  ${({ theme }) => `
    font-size: ${theme.getValue(26)}px;
  `}
`;

const CircleButton = styled.button`
  ${({ theme }) => `
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  color: ${theme.colors.white};
  border: 0px;
  background-color: ${theme.colors.primary};
  margin-bottom: 1rem;

  &:hover {
    background-color: black;
  }
  `}
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

export const TeamsMembers = () => {
  const { selectedTeam } = useTeamsContext();
  const { authUser } = useUserContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetRolesType>(GET_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const { data: membersData, loading: membersLoading } = useQuery<GetMembersType>(GET_MEMBERS, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const getRoleMembers = (roleName: string) => {
    return membersData?.members.data.filter((m) =>
      m.attributes.team_roles.data.some((role) => role.attributes.name === roleName),
    );
  };

  const getMemberRoles = (memberRoles: any[]) => {
    return memberRoles.filter(
      (role) => role.attributes.team.data.attributes.slug === selectedTeam?.slug,
    );
  };

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <Title>Members</Title>
        <div className="d-flex flex-row">
          {authUser?.role.name === Roles.Manager && (
            <div className="d-flex flex-row justify-content-end">
              <ManageButton
                variant="dark"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::member.member?page=1&pageSize=10&sort=full_name:ASC`,
                    '_blank',
                  );
                }}>
                <FaCog /> Manage
              </ManageButton>
            </div>
          )}
          <TeamDropdown />
        </div>
      </CustomRow>
      {authUser?.role.name === Roles.Manager && (
        <div className="d-flex flex-row justify-content-end">
          <CircleButton onClick={() => setOpenSearchModal(true)}>
            <FaSearch />
          </CircleButton>
        </div>
      )}
      <Row>
        <Col md={12}>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <Row>
                {data?.teamRoles.data.map((teamRole, idx) =>
                  sortBy(
                    getRoleMembers(teamRole.attributes.name) || [],
                    (element) => getMemberRoles(element.attributes.team_roles.data).length,
                  )?.map((member) => {
                    if (
                      range(0, idx).some((index) =>
                        (
                          getRoleMembers(data?.teamRoles?.data[index]?.attributes?.name || '') || []
                        ).some((auxMember) => auxMember.id === member.id),
                      )
                    ) {
                      return null;
                    } else {
                      return (
                        <Col key={member.id} md={4} className="mb-2">
                          <Card
                            onClick={() =>
                              navigate(Route.AdminTeamsMember.replace(':memberId', member.id))
                            }
                            style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                            <Card.Body>
                              <Card.Text className="d-flex flex-column" key={teamRole.id}>
                                <MemberName>{member.attributes.full_name}</MemberName>
                                {getMemberRoles(member.attributes.team_roles.data).map((role) => (
                                  <div
                                    key={role.attributes.name}
                                    className="d-flex flex-row align-items-center mt-2">
                                    <Circle
                                      color={
                                        role?.attributes?.color?.data?.attributes?.value || '#000'
                                      }
                                    />
                                    <RoleName>{role.attributes.name}</RoleName>
                                  </div>
                                ))}
                              </Card.Text>
                              <Card.Text>
                                <div className="d-flex flex-row justify-content-end">
                                  {member.attributes.badges.data
                                    .filter(
                                      (b) =>
                                        b.attributes.team.data.attributes.slug ===
                                        selectedTeam?.slug,
                                    )
                                    .map((badge) => (
                                      <OverlayTrigger
                                        key={badge.attributes.name}
                                        placement={'bottom'}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {badge.attributes.name}
                                          </Tooltip>
                                        }>
                                        <BadgeIcon
                                          src={badge.attributes.badge_icon.data.attributes.url}
                                        />
                                      </OverlayTrigger>
                                    ))}
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    }
                  }),
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <TeamsMemberSearchModal onHide={() => setOpenSearchModal(false)} show={openSearchModal} />
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>{loading || membersLoading ? <LoadingIndicator /> : renderContent()}</Container>
    </AdminLayout>
  );
};

export default TeamsMembers;
