/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import TeamDropdown from '../../components/TeamDropdown';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import { range, sortBy } from 'lodash';
import { useEffect, useState } from 'react';

export const GET_MEMBERS = gql`
  query GetTeamMembers($slug: String) {
    members(filters: { teams: { slug: { eq: $slug } } }, pagination: { pageSize: 100 }) {
      data {
        id
        attributes {
          full_name
          managers {
            team {
              data {
                attributes {
                  slug
                }
              }
            }
            member {
              data {
                id
                attributes {
                  full_name
                  team_roles {
                    data {
                      id
                      attributes {
                        name
                        organigram_level_position
                        team {
                          data {
                            attributes {
                              slug
                            }
                          }
                        }
                        color {
                          data {
                            attributes {
                              value
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          team_roles {
            data {
              attributes {
                name
                organigram_level_position
                team {
                  data {
                    attributes {
                      slug
                      name
                    }
                  }
                }
                color {
                  data {
                    attributes {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Member = {
  id: string;
  attributes: {
    managers: {
      team: {
        data: {
          attributes: {
            slug: string;
          };
        };
      };
      member: {
        data: {
          id: string;
          attributes: {
            full_name: string;
            team_roles: {
              data: {
                id: string;
                attributes: {
                  team: {
                    data: {
                      attributes: {
                        slug: string;
                      };
                    };
                  };
                  organigram_level_position: number;
                  name: string;
                  color: {
                    data: {
                      attributes: {
                        value: string;
                      };
                    };
                  };
                };
              }[];
            };
          };
        };
      };
    }[];
    full_name: string;
    team_roles: {
      data: {
        attributes: {
          name: string;
          team: {
            data: {
              attributes: {
                slug: string;
                name: string;
              };
            };
          };
          organigram_level_position: number;
          color: {
            data: {
              attributes: {
                value: string;
              };
            };
          };
        };
      }[];
    };
  };
};

type GetMembersType = {
  members: {
    data: Member[];
  };
};

export const GET_ROLES = gql`
  query GetTeamRoles($slug: String) {
    teamRoles(sort: ["organigram_level_position:asc"], filters: { team: { slug: { eq: $slug } } }) {
      data {
        id
        attributes {
          name
          description
          color {
            data {
              attributes {
                value
              }
            }
          }
          members {
            data {
              id
              attributes {
                full_name
              }
            }
          }
        }
      }
    }
  }
`;

type GetRolesType = {
  teamRoles: {
    data: {
      id: string;
      attributes: {
        name: string;
        description: string;
        color: {
          data: {
            attributes: {
              value: string;
            };
          };
        };
        members: {
          data: [
            {
              id: string;
              attributes: {
                full_name: string;
              };
            },
          ];
        };
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const Circle = styled.div<{ color: string }>`
  display: inline-block;
  ${({ theme, color }) => `
    width: ${theme.getValue(16)}px;
    border-radius: ${theme.getValue(16)}px;
    height: ${theme.getValue(16)}px;
    margin-right: ${theme.getValue(16)}px;
    background: ${color};
  `}
`;

const Rectangle = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    width: ${theme.getValue(16)}px;
    height: ${theme.getValue(32)}px;
    margin-right: ${theme.getValue(16)}px;
  `}
`;

const Connector = styled.div`
  ${({ theme }) => `
    margin-left: calc(50% - ${theme.getValue(2)}px);
    width: ${theme.getValue(4)}px;
    height: ${theme.getValue(56)}px;
    background-color: #D2D2D2
  `}
`;

const RoleColor = styled.div<{ color: string; height: string }>`
  display: flex;
  flex-direction: column;
  ${({ color, height }) => `
    width: 100%;
    height: ${height};
    background: ${color};
  `}
`;

export const TeamsOrganigram = () => {
  const { selectedTeam } = useTeamsContext();
  const [isDistributionView, setDistributionView] = useState(true);
  const [selectedMember, setSelectedMember] = useState<Member>();
  const { data, loading } = useQuery<GetRolesType>(GET_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const { data: membersData, loading: membersLoading } = useQuery<GetMembersType>(GET_MEMBERS, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  useEffect(() => {
    setDistributionView(true);
    setSelectedMember(undefined);
  }, [selectedTeam]);

  const getRoleMembers = (roleName: string) => {
    return membersData?.members.data.filter((m) =>
      m.attributes.team_roles.data.some((role) => role.attributes.name === roleName),
    );
  };

  const getMemberRoles = (memberRoles: any[]) => {
    return memberRoles.filter(
      (role) => role.attributes.team.data.attributes.slug === selectedTeam?.slug,
    );
  };

  const getMemberData = (memberId: string) => {
    return membersData?.members.data.find((m) => m.id === memberId);
  };

  const renderManagersContent = () => {
    const subMembersList = membersData?.members.data.filter((member) =>
      member.attributes.managers.some((manager) => manager.member.data.id === selectedMember?.id),
    );
    return (
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                setDistributionView(true);
              }}>
              Distribution
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Managers</Breadcrumb.Item>
          </Breadcrumb>
          {!!selectedMember?.attributes.managers.length && (
            <>
              <Card>
                <Card.Title className="p-2">Direct Managers</Card.Title>
                <Card.Body className="d-flex flex-row flex-wrap justify-content-around">
                  {selectedMember?.attributes.managers
                    .filter((m) => m.team.data.attributes.slug === selectedTeam?.slug)
                    .map((manager) => {
                      const teamRoles = sortBy(
                        manager.member.data.attributes.team_roles.data.filter(
                          (role) =>
                            role.attributes.team.data.attributes.slug === selectedTeam?.slug,
                        ),
                        (el) => el.attributes.organigram_level_position,
                      );

                      return (
                        <Col className="mb-4" key={manager.member.data.id} md={4}>
                          <Card
                            onClick={() => {
                              setSelectedMember(getMemberData(manager.member.data.id));
                              setDistributionView(false);
                            }}
                            style={{ width: '100%', cursor: 'pointer' }}>
                            <Card.Body>
                              <Card.Text className="d-flex flex-row align-items-center">
                                <strong>{manager.member.data.attributes.full_name}</strong>
                              </Card.Text>
                              {teamRoles.map((role) => (
                                <Card.Text
                                  key={role.attributes.name}
                                  className="d-flex flex-row align-items-center">
                                  <Circle color={role.attributes.color.data.attributes.value} />
                                  {role.attributes.name}
                                </Card.Text>
                              ))}
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                </Card.Body>
              </Card>
              <Connector />
            </>
          )}
          <Card>
            <Card.Title className="p-2">Selected Member</Card.Title>
            <Card.Body className="d-flex flex-row flex-wrap justify-content-center">
              <Col className="mb-4" md={4}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Text className="d-flex flex-row align-items-center">
                      <strong>{selectedMember?.attributes.full_name}</strong>
                    </Card.Text>
                    {sortBy(
                      selectedMember?.attributes.team_roles.data.filter(
                        (role) => role.attributes.team.data.attributes.slug === selectedTeam?.slug,
                      ),
                      (el) => el.attributes.organigram_level_position,
                    ).map((role) => (
                      <Card.Text
                        key={role.attributes.name}
                        className="d-flex flex-row align-items-center">
                        <Circle color={role.attributes.color.data.attributes.value} />
                        {role.attributes.name}
                      </Card.Text>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            </Card.Body>
          </Card>
          {!!subMembersList?.length && (
            <>
              <Connector />
              <Card>
                <Card.Title className="p-2">Direct Manager Of</Card.Title>
                <Card.Body className="d-flex flex-row flex-wrap justify-content-around">
                  {subMembersList.map((submember) => {
                    const teamRoles = sortBy(
                      submember.attributes.team_roles.data.filter(
                        (role) => role.attributes.team.data.attributes.slug === selectedTeam?.slug,
                      ),
                      (el) => el.attributes.organigram_level_position,
                    );

                    return (
                      <Col className="mb-4" key={submember.id} md={4}>
                        <div className="m-2">
                          <Card
                            onClick={() => {
                              setSelectedMember(getMemberData(submember.id));
                              setDistributionView(false);
                            }}
                            style={{ width: '100%', cursor: 'pointer' }}>
                            <Card.Body>
                              <Card.Text className="d-flex flex-row align-items-center">
                                <strong>{submember.attributes.full_name}</strong>
                              </Card.Text>
                              {teamRoles.map((role) => (
                                <Card.Text
                                  key={role.attributes.name}
                                  className="d-flex flex-row align-items-center">
                                  <Circle color={role.attributes.color.data.attributes.value} />
                                  {role.attributes.name}
                                </Card.Text>
                              ))}
                            </Card.Body>
                          </Card>
                        </div>
                      </Col>
                    );
                  })}
                </Card.Body>
              </Card>
            </>
          )}
        </Card.Body>
      </Card>
    );
  };

  const renderDistributionContent = () => {
    return (
      <Card style={{ width: '100%' }}>
        <Card.Body>
          <Breadcrumb>
            <Breadcrumb.Item active>Distribution</Breadcrumb.Item>
          </Breadcrumb>
          {data?.teamRoles.data.map((teamRole, idx) => (
            <Row key={teamRole.attributes.name} className="d-flex flex-row justify-content-center">
              {sortBy(
                getRoleMembers(teamRole.attributes.name) || [],
                (element) => getMemberRoles(element.attributes.team_roles.data).length,
              )?.map((member) => {
                if (
                  range(0, idx).some((index) =>
                    (
                      getRoleMembers(data?.teamRoles?.data[index]?.attributes?.name || '') || []
                    ).some((auxMember) => auxMember.id === member.id),
                  )
                ) {
                  return null;
                } else {
                  return (
                    <Col className="mb-4" key={member.id} md={4}>
                      <Card
                        onClick={() => {
                          setSelectedMember(member);
                          setDistributionView(false);
                        }}
                        style={{ width: '100%', cursor: 'pointer' }}>
                        <Card.Body>
                          <Card.Text
                            className="d-flex flex-row align-items-center"
                            key={teamRole.id}>
                            <Rectangle
                              color={teamRole.attributes.color.data?.attributes?.value || 'black'}>
                              {getMemberRoles(member.attributes.team_roles.data).map((role) => (
                                <RoleColor
                                  key={
                                    role.attributes.color?.data?.attributes.value ||
                                    new Date().getTime()
                                  }
                                  color={role.attributes.color?.data?.attributes.value}
                                  height={`${
                                    100 / getMemberRoles(member.attributes.team_roles.data).length
                                  }%`}
                                />
                              ))}
                            </Rectangle>
                            {member.attributes.full_name}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                }
              })}
            </Row>
          ))}
        </Card.Body>
      </Card>
    );
  };

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <Title>Organigram</Title>
        <TeamDropdown />
      </CustomRow>
      <Row>
        <Col md={3}>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              {data?.teamRoles.data.map((teamRole) => (
                <Card.Text key={teamRole.id}>
                  <Circle color={teamRole.attributes.color.data?.attributes?.value || 'black'} />
                  {teamRole.attributes.name}
                </Card.Text>
              ))}
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          {isDistributionView ? renderDistributionContent() : renderManagersContent()}
        </Col>
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>{loading || membersLoading ? <LoadingIndicator /> : renderContent()}</Container>
    </AdminLayout>
  );
};

export default TeamsOrganigram;
