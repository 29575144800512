/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Row } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import TeamDropdown from '../../components/TeamDropdown';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import useUserContext from '../../contexts/UserContext';
import { Roles } from '../../types/roles.d';
import { FaCog } from 'react-icons/fa';
import StarRating from '../../components/StartRating';

export const GET_SOFT_SKILLS = gql`
  query GetSoftSkills {
    softSkills {
      data {
        id
        attributes {
          name
          rating_descriptions {
            id
            description
            rating
          }
        }
      }
    }
  }
`;

type GetSoftSkillsType = {
  softSkills: {
    data: {
      id: string;
      attributes: {
        name: string;
        rating_descriptions: {
          id: string;
          description: string;
          rating: number;
        }[];
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

export const SoftSkills = () => {
  const { selectedTeam } = useTeamsContext();
  const { authUser } = useUserContext();
  const { data, loading } = useQuery<GetSoftSkillsType>(GET_SOFT_SKILLS, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <Title>Soft Skills</Title>
        <div className="d-flex flex-row">
          {authUser?.role.name === Roles.Manager && (
            <div className="d-flex flex-row justify-content-end">
              <ManageButton
                variant="dark"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::soft-skill.soft-skill?page=1&pageSize=10&sort=name:ASC`,
                    '_blank',
                  );
                }}>
                <FaCog /> Manage
              </ManageButton>
            </div>
          )}
          <TeamDropdown />
        </div>
      </CustomRow>
      <Row>
        <Accordion>
          {data?.softSkills.data.map((softSkill) => (
            <Accordion.Item eventKey={softSkill.id} key={softSkill.id}>
              <Accordion.Header>
                {/* <Circle color={teamRole.attributes.color.data?.attributes?.value || 'black'} /> */}
                {softSkill.attributes.name}
              </Accordion.Header>
              <Accordion.Body>
                {softSkill.attributes.rating_descriptions.map((ratingDescription) => (
                  <p key={ratingDescription.id}>
                    <StarRating rating={ratingDescription.rating} />
                    {ratingDescription.description}
                  </p>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>{loading ? <LoadingIndicator /> : renderContent()}</Container>
    </AdminLayout>
  );
};

export default SoftSkills;
