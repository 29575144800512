import Card from 'react-bootstrap/Card';
import { gql, useQuery } from '@apollo/client';
import Select from 'react-select';
import styled from 'styled-components';
import LoadingIndicator from '../../LoadingIndicator';
import { FaTimes } from 'react-icons/fa';
import { Badge, Button, Col, Dropdown, Form, Row, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { set } from 'lodash';
import { Route } from '../../../types/routes.d';
import { useNavigate } from 'react-router-dom';
import { GetFESkillsType, GetSoftSkillsType, ValueLabelType } from './types';
import {
  confidenceKeys,
  softMetricsList,
  softSkillsList,
  technicalMetricsList,
  technicalSkillsList,
} from './constants';

const FiltersRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  & > * {
    margin: 0.5rem;
  }
`;

const CustomBadge = styled(Badge)`
  ${({ theme }) => `
padding: 0.75rem 1rem;
background-color: ${theme.colors.primary} !important;
`}
`;

const CloseIcon = styled(FaTimes)`
  margin-left: 1rem;
  font-size: 1rem;
  cursor: pointer;
`;

export const GET_FE_TECH_SKILLS = gql`
  query GetFrontEndSkills($filters: SkillsFrontEndFiltersInput) {
    skillsFrontEnds(filters: $filters) {
      data {
        attributes {
          member {
            data {
              id
              attributes {
                full_name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FE_SOFT_SKILLS = gql`
  query GetFrontEndSoftSkills($filters: SkillsSoftSkillFiltersInput) {
    skillsSoftSkills(filters: $filters) {
      data {
        attributes {
          member {
            data {
              id
              attributes {
                full_name
              }
            }
          }
        }
      }
    }
  }
`;

const skillTypesOptions = [
  { label: 'Technical Skill', value: 'technical' },
  { label: 'Soft Skill', value: 'soft' },
];

export const FESkillsSearch = () => {
  const [selectedSkillType, setSelectedSkillType] = useState({ value: '', label: '' });
  const [selectedSkill, setSelectedSkill] = useState({ value: '', label: '' });
  const [value, setValue] = useState<any>('');
  const [appliedFilters, setAppliedFilters] = useState<any>([]);
  const [selectedMetric, setSelectedMetric] = useState<
    ValueLabelType & { filters: ValueLabelType[] }
  >();
  const [selectedFilter, setSelectedFilter] = useState<ValueLabelType>();

  const navigate = useNavigate();

  const parseSelectedFilters = (type: string) => {
    let finalObject = {};
    const filteredData = appliedFilters.filter((f: any) => f.value.type === type);
    for (let index = 0; index < filteredData.length; index++) {
      const element = filteredData[index];
      const cObject = {
        [element.skill.value]: {
          [element.metric.value]: {
            [element.filter.value]: element.value.value,
          },
        },
      };
      if (index === 0) {
        finalObject = cObject;
      } else {
        set(finalObject, new Array(index).fill('and').join('.'), cObject);
      }
    }
    return finalObject;
  };

  const { data: feSkillsData, loading: feLoading } = useQuery<GetFESkillsType>(GET_FE_TECH_SKILLS, {
    fetchPolicy: 'no-cache',
    variables: {
      filters: parseSelectedFilters('technical'),
    },
  });

  const { data: softSkillsData, loading: softSkillsLoading } = useQuery<GetSoftSkillsType>(
    GET_FE_SOFT_SKILLS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        filters: parseSelectedFilters('soft'),
      },
    },
  );

  console.log(parseSelectedFilters('soft'), appliedFilters);

  const getResults = () => {
    if (!appliedFilters.some((a: any) => a.value.type === 'technical')) {
      return softSkillsData?.skillsSoftSkills.data;
    }

    if (!appliedFilters.some((a: any) => a.value.type === 'soft')) {
      return feSkillsData?.skillsFrontEnds.data;
    }

    return feSkillsData?.skillsFrontEnds.data.filter((feMember) =>
      softSkillsData?.skillsSoftSkills.data.some(
        (softMember) => softMember.attributes.member.data.id === feMember.attributes.member.data.id,
      ),
    );
  };

  const getConfidenceValuesByFilter = () => {
    if (selectedFilter?.label === 'Greater than') {
      return confidenceKeys.filter((ck) => ck.number > value.number).map((v) => v.value);
    }
    if (selectedFilter?.label === 'Less than') {
      return confidenceKeys.filter((ck) => ck.number < value.number).map((v) => v.value);
    }
    return [value.value];
  };

  useEffect(() => {
    setSelectedFilter(undefined);
    setSelectedMetric(undefined);
    setSelectedSkill({ value: '', label: '' });
    setValue('');
  }, [selectedSkillType]);

  const currentSkillsList =
    selectedSkillType.value === 'technical' ? technicalSkillsList : softSkillsList;
  const currentMetricsList =
    selectedSkillType.value === 'technical' ? technicalMetricsList : softMetricsList;

  const renderFilterContent = () => {
    if (!selectedSkill || !selectedMetric) {
      return null;
    }
    if (selectedMetric?.value === 'years_of_experience' || selectedMetric?.value === 'points') {
      return (
        <>
          <Dropdown>
            <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
              {selectedFilter?.label || 'Select an Option'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {selectedMetric.filters.map((filter) => (
                <Dropdown.Item
                  onClick={() => {
                    setSelectedFilter(filter);
                  }}
                  key={filter.value}>
                  {filter.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {!!selectedFilter && (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                value={value}
                onChange={(e) => setValue(e.target.value)}
                type="number"
                placeholder="Enter value"
              />
            </Form.Group>
          )}
          <Button
            disabled={!value}
            onClick={() => {
              setAppliedFilters([
                ...appliedFilters,
                {
                  skill: selectedSkill,
                  metric: selectedMetric,
                  filter: selectedFilter,
                  value: { label: value, value: Number(value), type: selectedSkillType.value },
                },
              ]);
              setSelectedSkillType({ value: '', label: '' });
              setSelectedFilter(undefined);
              setSelectedMetric(undefined);
              setSelectedSkill({ value: '', label: '' });
              setValue('');
            }}
            variant="success">
            Add
          </Button>
        </>
      );
    }

    if (selectedMetric?.value === 'level_of_confidence') {
      return (
        <>
          <Dropdown>
            <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
              {selectedFilter?.label || 'Select an Option'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {selectedMetric.filters.map((filter) => (
                <Dropdown.Item
                  onClick={() => {
                    setSelectedFilter(filter);
                  }}
                  key={filter.value}>
                  {filter.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {!!selectedFilter && (
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                {value?.value || 'Select an Option'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {confidenceKeys.map((confidence) => (
                  <Dropdown.Item
                    onClick={() => {
                      setValue(confidence);
                    }}
                    key={confidence.value}>
                    {confidence.value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            disabled={!value}
            onClick={() => {
              setAppliedFilters([
                ...appliedFilters,
                {
                  skill: selectedSkill,
                  metric: selectedMetric,
                  filter: selectedFilter,
                  value: { label: value.value, value: getConfidenceValuesByFilter() },
                },
              ]);
              setSelectedFilter(undefined);
              setSelectedMetric(undefined);
              setSelectedSkill({ value: '', label: '' });
              setValue('');
            }}
            variant="success">
            Add
          </Button>
        </>
      );
    }
  };

  const renderContent = () => (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <FiltersRow className="d-flex flex-row">
          <Select
            options={skillTypesOptions as any}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                minWidth: 150,
              }),
            }}
            onChange={(newValue) => setSelectedSkillType(newValue as any)}
            value={selectedSkillType}
          />
          {selectedSkillType.value && (
            <Select
              options={
                currentSkillsList.map((skill) => ({
                  label: skill.label,
                  value: skill.value,
                })) as any
              }
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  minWidth: 150,
                }),
              }}
              onChange={(newValue) => setSelectedSkill(newValue as any)}
              value={selectedSkill}
            />
          )}
          {selectedSkill.value && (
            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                {selectedMetric?.label || 'Select a Metric'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {currentMetricsList.map((metric) => (
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedMetric(metric);
                    }}
                    key={metric.value}>
                    {metric.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {renderFilterContent()}
        </FiltersRow>
        <Row>
          <FiltersRow className="d-flex flex-row">
            {appliedFilters.map((aFilter: any, idx: number) => (
              <CustomBadge key={idx} bg="dark">
                {`${aFilter.skill.label} - ${aFilter.metric.label} - ${aFilter.filter.label} - ${aFilter.value.label}`}
                <CloseIcon
                  onClick={() => {
                    setAppliedFilters(
                      appliedFilters.filter((_: any, i: number) => {
                        return i !== idx;
                      }),
                    );
                  }}
                />
              </CustomBadge>
            ))}
          </FiltersRow>
        </Row>
        {!!appliedFilters.length && (
          <Row className="mt-5">
            <Col md={12}>
              <h6>Results</h6>
            </Col>
            <Col md={12}>
              <Card>
                <Card.Text>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Full Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {getResults()?.map((sk) => (
                        <tr key={sk.attributes.member.data.id}>
                          <td>{sk.attributes.member.data.id}</td>
                          <td>{sk.attributes.member.data.attributes.full_name}</td>
                          <td>
                            <Button
                              onClick={() =>
                                navigate(
                                  Route.AdminTeamsMember.replace(
                                    ':memberId',
                                    sk.attributes.member.data.id,
                                  ),
                                )
                              }
                              variant="dark">
                              View Profile
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Text>
              </Card>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );

  return feLoading || softSkillsLoading ? <LoadingIndicator /> : renderContent();
};

export default FESkillsSearch;
