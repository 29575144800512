import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      email
      id
      role {
        id
        name
      }
    }
  }
`;

export const GET_CURRENT_USER_INFO = gql`
  query GetCurrentUserInfo($id: ID) {
    usersPermissionsUser(id: $id) {
      data {
        attributes {
          full_name
          company_role
        }
      }
    }
  }
`;
