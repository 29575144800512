/* eslint-disable import/no-named-as-default */
import { useCallback, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
// import { Home } from './screens/Home';
// import { Newsletters } from './screens/Newsletters';
import { useDeviceDetector } from './hooks/useDeviceDetector';
import { useContainerDimensions } from './hooks/useContainerDimensions';
import { Route as AppRoute } from './types/routes.d';
import { colors, ColorsType, fonts, FontsType, breakpoints, BreakpointsType } from './config/theme';
import { getHeight, getWidth } from './utils/ui';
// import Newsletter from './screens/Newsletter';
// import Article from './screens/Article';
// import ImportantArticles from './screens/ImportantArticles';
import client from './graphql/client';
import { TeamsProvider } from './contexts/TeamsContext';
import Dashboard from './screens/admin/Dashboard';
import TeamsMembers from './screens/admin/TeamsMembers';
import TeamsRoles from './screens/admin/TeamsRoles';
import TeamsOrganigram from './screens/admin/TeamsOrganigram';
// import FeedbackCenter from './screens/FeedbackCenter';
import { Toaster } from 'react-hot-toast';
import Login from './screens/Login';
import TeamMember from './screens/admin/TeamMember';
import { UserProvider } from './contexts/UserContext';
import Feedbacks from './screens/admin/Feedbacks';
import TeamsBadges from './screens/admin/TeamsBadges';
import TeamsDocuments from './screens/admin/TeamsDocuments';
import SoftSkills from './screens/admin/SoftSkills';
const Wrapper = styled.div<{ containerHeight: number }>`
  height: ${({ containerHeight }) => `${containerHeight}px`};
`;

type Theme = {
  getValue: (value: number) => number;
  getValueHeight: (value: number) => number;
  getFontSize: (value: number) => number;
  breakpoints: BreakpointsType;
  colors: ColorsType;
  fonts: FontsType;
  isMobile: boolean;
};

function App() {
  const { isMobile } = useDeviceDetector();
  const containerDimensions = useContainerDimensions();

  const getValue = useCallback(
    (value: number): number => {
      return getWidth({
        containerDimensions,
        isMobile,
        value,
      });
    },
    [containerDimensions, isMobile],
  );

  const getValueHeight = useCallback(
    (value: number): number => {
      return getHeight({
        containerDimensions,
        isMobile,
        value,
      });
    },
    [containerDimensions, isMobile],
  );

  const theme = useMemo(
    (): Theme => ({
      getValue,
      getValueHeight,
      colors,
      fonts,
      breakpoints,
      getFontSize: getValue,
      isMobile,
    }),
    [getValue, isMobile],
  );

  const renderRouter = () => (
    <Routes>
      {/* <Route path={AppRoute.Home} element={<Home />} />
      <Route path={AppRoute.Newsletters} element={<Newsletters />} />
      <Route path={AppRoute.Newsletter} element={<Newsletter />} />
      <Route path={AppRoute.Article} element={<Article />} />
      <Route path={AppRoute.FeedbackCenter} element={<FeedbackCenter />} />
      <Route path={AppRoute.ImportantLinks} element={<ImportantArticles />} /> */}
      {/* <Route path={AppRoute.Home} element={<Home />} /> */}
      {/* Admin */}
      <Route path={'/admin'} element={<Navigate to={AppRoute.AdminDashboard} />} />
      <Route path={AppRoute.AdminLogin} element={<Login />} />
      <Route path={AppRoute.AdminDashboard} element={<Dashboard />} />
      <Route path={AppRoute.AdminFeedbacks} element={<Feedbacks />} />
      <Route path={AppRoute.AdminSoftSkills} element={<SoftSkills />} />
      <Route path={AppRoute.AdminTeamsMembers} element={<TeamsMembers />} />
      <Route path={AppRoute.AdminTeamsMember} element={<TeamMember />} />
      <Route path={AppRoute.AdminTeamsRoles} element={<TeamsRoles />} />
      <Route path={AppRoute.AdminTeamsBadges} element={<TeamsBadges />} />
      <Route path={AppRoute.AdminTeamsDocuments} element={<TeamsDocuments />} />
      <Route path={AppRoute.AdminTeamsOrganigram} element={<TeamsOrganigram />} />
      <Route path={'*'} element={<Navigate to={AppRoute.AdminDashboard} />} />
    </Routes>
  );

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <>
          <Toaster position="top-right" reverseOrder={false} />
          <Router>
            <UserProvider>
              <TeamsProvider>
                <Wrapper containerHeight={containerDimensions.height}>{renderRouter()}</Wrapper>
              </TeamsProvider>
            </UserProvider>
          </Router>
        </>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
