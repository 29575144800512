/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { Button, Row } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import TeamDropdown from '../../components/TeamDropdown';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import useUserContext from '../../contexts/UserContext';
import { Roles } from '../../types/roles.d';
import { FaCog } from 'react-icons/fa';

export const GET_ROLES = gql`
  query GetTeamRoles($slug: String) {
    teamRoles(sort: ["organigram_level_position:asc"], filters: { team: { slug: { eq: $slug } } }) {
      data {
        id
        attributes {
          name
          description
          color {
            data {
              attributes {
                value
              }
            }
          }
          members {
            data {
              id
              attributes {
                full_name
              }
            }
          }
        }
      }
    }
  }
`;

type GetRolesType = {
  teamRoles: {
    data: {
      id: string;
      attributes: {
        name: string;
        description: string;
        color: {
          data: {
            attributes: {
              value: string;
            };
          };
        };
        members: {
          data: [
            {
              id: string;
              attributes: {
                full_name: string;
              };
            },
          ];
        };
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const Circle = styled.div<{ color: string }>`
  display: inline-block;
  ${({ theme, color }) => `
    width: ${theme.getValue(16)}px;
    border-radius: ${theme.getValue(16)}px;
    height: ${theme.getValue(16)}px;
    margin-right: ${theme.getValue(16)}px;
    background: ${color};
  `}
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

export const TeamsRoles = () => {
  const { selectedTeam } = useTeamsContext();
  const { authUser } = useUserContext();
  const { data, loading } = useQuery<GetRolesType>(GET_ROLES, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <Title>Roles</Title>
        <div className="d-flex flex-row">
          {authUser?.role.name === Roles.Manager && (
            <div className="d-flex flex-row justify-content-end">
              <ManageButton
                variant="dark"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::team-role.team-role?page=1&pageSize=10&sort=name:ASC&filters[$and][0][team][name][$eq]=${selectedTeam?.name}`,
                    '_blank',
                  );
                }}>
                <FaCog /> Manage
              </ManageButton>
            </div>
          )}
          <TeamDropdown />
        </div>
      </CustomRow>
      <Row>
        <Accordion>
          {data?.teamRoles.data.map((teamRole) => (
            <Accordion.Item eventKey={teamRole.id} key={teamRole.id}>
              <Accordion.Header>
                <Circle color={teamRole.attributes.color.data?.attributes?.value || 'black'} />
                {teamRole.attributes.name}
              </Accordion.Header>
              <Accordion.Body
                dangerouslySetInnerHTML={{
                  __html: teamRole.attributes.description,
                }}></Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>{loading ? <LoadingIndicator /> : renderContent()}</Container>
    </AdminLayout>
  );
};

export default TeamsRoles;
