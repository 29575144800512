export enum Route {
  Home = '/',
  Newsletters = '/newsletters/:slug',
  Newsletter = '/newsletter/:slug',
  Article = '/article/:slug',
  ImportantLinks = '/important-links',
  FeedbackCenter = '/feedback-center',
  // Admin
  AdminLogin = '/admin/login',
  AdminDashboard = '/admin/dashboard',
  AdminFeedbacks = '/admin/feedbacks',
  AdminSoftSkills = '/admin/soft-skills',
  AdminTeamsRoles = '/admin/teams/roles',
  AdminTeamsMembers = '/admin/teams/members',
  AdminTeamsBadges = '/admin/teams/badges',
  AdminTeamsDocuments = '/admin/teams/documents',
  AdminTeamsMember = '/admin/teams/members/:memberId',
  AdminTeamsOrganigram = '/admin/teams/organigram',
}
