import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import styled from 'styled-components';

const Container = styled.div`
  span {
    font-size: 1.25rem;
  }
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
  .empty-star {
    fill: ${({ theme }) => theme.colors.gray};
  }
`;

const StarRating = ({ rating }: { rating: number }) => {
  const MAX_STARS = 5;
  const stars = [];

  for (let i = 1; i <= MAX_STARS; i++) {
    if (i <= rating) {
      // Filled star
      stars.push(<FaStar key={i} />);
    } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
      // Half star
      stars.push(<FaStarHalfAlt key={i} />);
    } else {
      // Empty star
      stars.push(<FaStar key={i} className="empty-star" />);
    }
  }

  return (
    <Container className="star-rating">
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </Container>
  );
};

export default StarRating;
