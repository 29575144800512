/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import TeamDropdown from '../../components/TeamDropdown';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Route } from '../../types/routes.d';
import { FaCog } from 'react-icons/fa';
import { Roles } from '../../types/roles.d';
import useUserContext from '../../contexts/UserContext';

export const GET_BADGES = gql`
  query GetTeamBadges($slug: String) {
    badges(filters: { team: { slug: { eq: $slug } } }) {
      data {
        id
        attributes {
          name
          description
          badge_icon {
            data {
              attributes {
                url
              }
            }
          }
          members {
            data {
              id
              attributes {
                full_name
              }
            }
          }
        }
      }
    }
  }
`;

type GetBadgesType = {
  badges: {
    data: {
      id: string;
      attributes: {
        name: string;
        description: string;
        badge_icon: {
          data: {
            attributes: {
              url: string;
            };
          };
        };
        members: {
          data: [
            {
              id: string;
              attributes: {
                full_name: string;
              };
            },
          ];
        };
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const MemberCard = styled(Card)`
  ${({ theme }) => `
  padding: ${theme.getValue(24)}px ${theme.getValue(16)}px;
  cursor: pointer;
  `}
`;

const BadgeIcon = styled.img`
  ${({ theme }) => `
  width: ${theme.getValue(56)}px;
  height: ${theme.getValue(56)}px;
  object-fit: contain;
  object-position: center;
  margin-right: ${theme.getValue(8)}px;
  `}
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

export const TeamsBadges = () => {
  const { selectedTeam } = useTeamsContext();
  const { authUser } = useUserContext();
  const navigate = useNavigate();
  const { data, loading } = useQuery<GetBadgesType>(GET_BADGES, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <Title>Badges</Title>
        <div className="d-flex flex-row">
          {authUser?.role.name === Roles.Manager && (
            <div className="d-flex flex-row justify-content-end">
              <ManageButton
                variant="dark"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::badge.badge?page=1&pageSize=10&sort=name:ASC&filters[$and][0][team][name][$eq]=${selectedTeam?.name}`,
                    '_blank',
                  );
                }}>
                <FaCog /> Manage
              </ManageButton>
            </div>
          )}
          <TeamDropdown />
        </div>
      </CustomRow>
      <Row>
        <Accordion>
          {data?.badges.data.map((badge) => (
            <Accordion.Item eventKey={badge.id} key={badge.id}>
              <Accordion.Header>
                <BadgeIcon src={badge.attributes.badge_icon.data.attributes.url} />
                {badge.attributes.name}
              </Accordion.Header>
              <Accordion.Body>
                <div dangerouslySetInnerHTML={{ __html: badge.attributes.description }} />
                <p className="mt-4">
                  <strong>Members</strong>
                </p>
                <Row>
                  {badge.attributes.members.data.map((member) => (
                    <Col md={4} key={member.id}>
                      <MemberCard
                        onClick={() =>
                          navigate(Route.AdminTeamsMember.replace(':memberId', member.id))
                        }>
                        <Card.Text>{member.attributes.full_name}</Card.Text>
                      </MemberCard>
                    </Col>
                  ))}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>{loading ? <LoadingIndicator /> : renderContent()}</Container>
    </AdminLayout>
  );
};

export default TeamsBadges;
