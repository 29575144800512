import Dropdown from 'react-bootstrap/Dropdown';
import useTeamsContext from '../contexts/TeamsContext';
import { useEffect } from 'react';

export const TeamDropdown = () => {
  const { teams, selectedTeam, setSelectedTeam } = useTeamsContext();

  const filteredTeams = teams.filter((team) => !team.is_general);

  useEffect(() => {
    if (!selectedTeam && filteredTeams.length) setSelectedTeam(filteredTeams[0]);
  }, [filteredTeams, selectedTeam]);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="dark" id="dropdown-basic">
        {selectedTeam?.name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {filteredTeams.map((team) => (
          <Dropdown.Item key={team.slug} onClick={() => setSelectedTeam(team)}>
            {team.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TeamDropdown;
