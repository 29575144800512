import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const Wrapper = styled.div<{ size: string }>`
  ${({ theme, size }) => `
  margin-top: ${theme.getValue(16)}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${
    size === 'Large'
      ? `
      .spinner-border {
        --bs-spinner-width: 5rem;
        --bs-spinner-height: 5rem;
        --bs-spinner-vertical-align: -0.125em;
        --bs-spinner-border-width: 0.55em;

        border: var(--bs-spinner-border-width) solid ${theme.colors.primary};
        border-right-color: transparent;
      }
  `
      : `
      .spinner-border {
        border: var(--bs-spinner-border-width) solid ${theme.colors.primary};
        border-right-color: transparent;
      }`
  }
`}
`;

interface Props {
  size?: 'Large' | 'Small';
}

function LoadingIndicator({ size = 'Large' }: Props) {
  return (
    <Wrapper size={size}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Wrapper>
  );
}

export default LoadingIndicator;
