import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export const useContainerDimensions = (): { height: number; width: number } => {
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      setContainerDimensions({ width: innerWidth, height: innerHeight });
    }
    window.addEventListener('resize', debounce(handleResize, 300));
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return containerDimensions;
};
