export const confidenceKeys = [
  { value: 'No Previous Experience', number: 1 },
  { value: 'Just a Little', number: 2 },
  { value: 'Kind of Comfortable', number: 3 },
  { value: 'Comfortable', number: 4 },
  { value: 'Very Comfortable', number: 5 },
];

export const softSkillsList = [
  { label: 'Accountability', value: 'accountability' },
  { label: 'Problem Solving', value: 'problem_solving' },
  { label: 'Leadership', value: 'leadership' },
  { label: 'Teamwork', value: 'teamwork' },
  { label: 'Continuous Learning', value: 'continuous_learning' },
  { label: 'Attention to Detail', value: 'attention_to_detail' },
  { label: 'Communication', value: 'communication' },
  { label: 'Proactivity', value: 'proactivity' },
  { label: 'Empathy', value: 'empathy' },
  { label: 'Creativity', value: 'creativity' },
  { label: 'Mentorship', value: 'mentorship' },
  { label: 'Time Management', value: 'time_management' },
  { label: 'Adaptability', value: 'adaptability' },
];

export const technicalSkillsList = [
  {
    label: 'HTML',
    value: 'html',
  },
  {
    label: 'CSS',
    value: 'css',
  },
  {
    label: 'JavaScript',
    value: 'javascript',
  },
  {
    label: 'JQuery',
    value: 'jquery',
  },
  {
    label: 'ReactJS',
    value: 'reactjs',
  },
  {
    label: 'VueJS',
    value: 'vuejs',
  },
  {
    label: 'AngularJS',
    value: 'angular',
  },
  {
    label: 'Shopify',
    value: 'shopify',
  },
  {
    label: 'Wordpress',
    value: 'wordpress',
  },
  {
    label: 'GA & GTM',
    value: 'google_analytics_and_tag_manager',
  },
  {
    label: 'SEO',
    value: 'seo',
  },
  {
    label: 'Accessibility',
    value: 'accessibility',
  },
  {
    label: 'React Native',
    value: 'react_native',
  },
  {
    label: 'Native Mobile Development',
    value: 'native_mobile_development',
  },
  {
    label: 'GatsbyJS',
    value: 'gatsbyjs',
  },
  {
    label: 'NextJS',
    value: 'nextjs',
  },
  {
    label: 'Directus',
    value: 'directus',
  },
  {
    label: 'Strapi',
    value: 'strapi',
  },
  {
    label: 'XR',
    value: 'xr',
  },
  {
    label: 'BE Frameworks',
    value: 'be_frameworks',
  },
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
  {
    label: 'Machine Learning',
    value: 'machine_learning',
  },
];

export const technicalMetricsList = [
  {
    label: 'Years Of Experience',
    value: 'years_of_experience',
    type: 'number',
    filters: [
      { label: 'Equals', value: 'eq' },
      { label: 'Greater than', value: 'gt' },
      { label: 'Less than', value: 'lt' },
    ],
  },
  {
    label: 'Level Of Confidence',
    value: 'level_of_confidence',
    type: 'confidence',
    filters: [
      { label: 'Equals', value: 'in' },
      { label: 'Greater than', value: 'in' },
      { label: 'Less than', value: 'in' },
    ],
  },
];

export const softMetricsList = [
  {
    label: 'Points',
    value: 'points',
    type: 'number',
    filters: [
      { label: 'Equals', value: 'eq' },
      { label: 'Greater than', value: 'gt' },
      { label: 'Less than', value: 'lt' },
    ],
  },
];
