/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import TeamDropdown from '../../components/TeamDropdown';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import { FaCog, FaConfluence, FaExternalLinkAlt, FaFilePdf } from 'react-icons/fa';
import { MdPublic } from 'react-icons/md';
import { Roles } from '../../types/roles.d';
import useUserContext from '../../contexts/UserContext';

export const GET_TEAM_DOCUMENTS = gql`
  query GetTeamDocuments($slug: String) {
    teams(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          documents_categories {
            id
            title
            documents {
              id
              name
              internal_file {
                data {
                  attributes {
                    url
                  }
                }
              }
              external_link
              public
              type
            }
          }
        }
      }
    }
  }
`;

type GetDocumentsType = {
  teams: {
    data: {
      attributes: {
        documents_categories: {
          id: string;
          title: string;
          documents: {
            id: string;
            name: string;
            internal_file: {
              data: {
                attributes: {
                  url: string;
                };
              };
            };
            external_link: string;
            public: boolean;
            type: string;
          }[];
        }[];
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

const DocumentCard = styled(Card)`
  cursor: pointer;
  padding-top: 1rem;
  position: relative;
  height: 100%;

  .public-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TeamsDocuments = () => {
  const { selectedTeam } = useTeamsContext();
  const { authUser } = useUserContext();
  const { data, loading } = useQuery<GetDocumentsType>(GET_TEAM_DOCUMENTS, {
    fetchPolicy: 'no-cache',
    variables: {
      slug: selectedTeam?.slug || '',
    },
  });

  const teamData = data?.teams?.data[0];

  console.log(selectedTeam);
  const getIconComponent = (type: string) => {
    if (type === 'Confluence') {
      return <FaConfluence size={'2rem'} />;
    }
    if (type === 'Internal') {
      return <FaFilePdf size={'2rem'} />;
    }
    return <FaExternalLinkAlt size={'2rem'} />;
  };

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <Title>Documents</Title>
        <div className="d-flex flex-row">
          {authUser?.role.name === Roles.Manager && (
            <div className="d-flex flex-row justify-content-end">
              <ManageButton
                variant="dark"
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::team.team?page=1&pageSize=10&sort=name:ASC&filters[$and][0][slug][$eq]=${selectedTeam?.slug}`,
                    '_blank',
                  );
                }}>
                <FaCog /> Manage
              </ManageButton>
            </div>
          )}
          <TeamDropdown />
        </div>
      </CustomRow>
      <Row>
        {teamData?.attributes.documents_categories.map((documentCategory) => (
          <Col className="mb-3" key={documentCategory.id} sm={12}>
            <Card>
              <Card.Title className="p-4 pb-1">
                <h4>{documentCategory.title}</h4>
              </Card.Title>
              <Card.Body>
                <Row>
                  {documentCategory.documents
                    .filter((d) => (authUser?.role.name === Roles.Manager ? true : d.public))
                    .map((document) => (
                      <Col key={document.id} md={3}>
                        <DocumentCard
                          onClick={() => {
                            window.open(
                              document.type === 'Internal'
                                ? document.internal_file.data.attributes.url
                                : document.external_link,
                              '_blank',
                            );
                          }}>
                          <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                            {!!document.public && <MdPublic className="public-icon" />}
                            {getIconComponent(document.type)}
                            <div className="mt-3 text-center">{document.name}</div>
                          </Card.Body>
                        </DocumentCard>
                      </Col>
                    ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>{loading ? <LoadingIndicator /> : renderContent()}</Container>
    </AdminLayout>
  );
};

export default TeamsDocuments;
