import styled from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import { Button, Col, Row } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import LoadingIndicator from './LoadingIndicator';
import { useParams } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import { Roles } from '../types/roles.d';
import useUserContext from '../contexts/UserContext';

const CategoryHeader = styled.div`
  ${({ theme }) => `
    width: 100%;
    font-size: ${theme.getValue(22)}px;
    margin: ${theme.getValue(16)}px 0px ${theme.getValue(8)}px;
    opacity: 0.8;
    `}
`;

const SkillTitle = styled.div`
  ${({ theme }) => `
    width: 100%;
    font-size: ${theme.getValue(18)}px;
    font-weight: 700;
    margin-bottom: ${theme.getValue(8)}px;
    `}
`;

const SkillRowName = styled.span`
  ${({ theme }) => `
    font-size: ${theme.getValue(18)}px;
    margin-right: ${theme.getValue(8)}px;
    `}
`;

const SkillRowValue = styled.strong`
  ${({ theme }) => `
    font-size: ${theme.getValue(20)}px;
    `}
`;

const NoDataContent = styled.div`
  ${({ theme }) => `
margin-bottom: ${theme.getValue(16)}px;
`}
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

export const GET_FE_SKILLS = gql`
  query GetFrontEndSkills($id: ID) {
    skillsFrontEnds(filters: { member: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          member {
            data {
              id
              attributes {
                full_name
              }
            }
          }
          html {
            years_of_experience
            level_of_confidence
          }
          css {
            years_of_experience
            level_of_confidence
          }
          javascript {
            years_of_experience
            level_of_confidence
          }
          jquery {
            years_of_experience
            level_of_confidence
          }
          reactjs {
            years_of_experience
            level_of_confidence
          }
          vuejs {
            years_of_experience
            level_of_confidence
          }
          angular {
            years_of_experience
            level_of_confidence
          }
          shopify {
            years_of_experience
            level_of_confidence
          }
          wordpress {
            years_of_experience
            level_of_confidence
          }
          react_native {
            years_of_experience
            level_of_confidence
          }
          native_mobile_development {
            years_of_experience
            level_of_confidence
          }
          gatsbyjs {
            years_of_experience
            level_of_confidence
          }
          nextjs {
            years_of_experience
            level_of_confidence
          }
          directus {
            years_of_experience
            level_of_confidence
          }
          strapi {
            years_of_experience
            level_of_confidence
          }
          google_analytics_and_tag_manager {
            years_of_experience
            level_of_confidence
          }
          seo {
            years_of_experience
            level_of_confidence
          }
          accessibility {
            years_of_experience
            level_of_confidence
          }
          xr {
            years_of_experience
            level_of_confidence
          }
          be_frameworks {
            years_of_experience
            level_of_confidence
          }
          blockchain {
            years_of_experience
            level_of_confidence
          }
          machine_learning {
            years_of_experience
            level_of_confidence
          }
        }
      }
    }
  }
`;

type GetFESkillsType = {
  skillsFrontEnds: {
    data: {
      id: string;
      attributes: {
        member: {
          data: {
            id: string;
            attributes: {
              full_name: string;
            };
          };
        };
        html: {
          years_of_experience: number;
          level_of_confidence: string;
        };
        css: {
          years_of_experience: number;
          level_of_confidence: string;
        };
        javascript: {
          years_of_experience: number;
          level_of_confidence: string;
        };
        jquery: {
          years_of_experience: number;
          level_of_confidence: string;
        };
        reactjs: {
          years_of_experience: number;
          level_of_confidence: string;
        };
        vuejs: {
          years_of_experience: number;
          level_of_confidence: string;
        };
        angular: {
          years_of_experience: number;
          level_of_confidence: string;
        };
      };
    }[];
  };
};

export const FESkills = () => {
  const { memberId } = useParams();
  const { authUser } = useUserContext();

  const { data: feSkillsData, loading: feLoading } = useQuery<GetFESkillsType>(GET_FE_SKILLS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: memberId,
    },
  });

  const confidenceKeys = {
    No_previous_experience: 'No Previous Experience',
    Just_a_Little: 'Just a Little',
    Kind_of_Comfortable: 'Kind of Comfortable',
    Comfortable: 'Comfortable',
    Very_Comfortable: 'Very Comfortable',
  };

  const hasDataForSections = (keys: string[]) => {
    if (
      keys.some(
        (key) =>
          (feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any) &&
          (feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any)[key],
      )
    ) {
      return null;
    } else {
      return <NoDataContent>No data available</NoDataContent>;
    }
  };

  const renderCardProgress = (title: string, skillKey: string) => {
    if (
      !(feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any) ||
      !(feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any)[skillKey]
    ) {
      return null;
    }

    const getConfidenceBarProps = (cKey: string) => {
      if (cKey === confidenceKeys.No_previous_experience) {
        return { variant: 'danger', now: 1 };
      }
      if (cKey === confidenceKeys.Just_a_Little) {
        return { variant: 'danger', now: 25 };
      }
      if (cKey === confidenceKeys.Kind_of_Comfortable) {
        return { variant: 'warning', now: 50 };
      }
      if (cKey === confidenceKeys.Comfortable) {
        return { variant: 'success', now: 75 };
      }
      return { variant: 'success', now: 100 };
    };

    const getExperienceBarProps = (years: number) => {
      console.log(years);
      if (years < 1) {
        return { variant: 'danger', now: 1 };
      }
      if (years <= 2) {
        return { variant: 'danger', now: 25 };
      }
      if (years <= 3) {
        return { variant: 'warning', now: 50 };
      }
      if (years <= 4) {
        return { variant: 'success', now: 75 };
      }
      return { variant: 'success', now: 100 };
    };

    return (
      <Col md={6} className="mb-2">
        <Card style={{ height: '100%' }}>
          <Card.Body>
            <SkillTitle>{title}</SkillTitle>
            <div>
              <SkillRowName>Years of experience:</SkillRowName>
              <SkillRowValue>
                {
                  (feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any)[skillKey]
                    .years_of_experience
                }
              </SkillRowValue>
              <ProgressBar
                {...getExperienceBarProps(
                  Number(
                    (feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any)[skillKey]
                      .years_of_experience,
                  ),
                )}
              />
            </div>
            <div className="mt-2">
              <SkillRowName>Level of Confidence:</SkillRowName>
              <SkillRowValue>
                {
                  (confidenceKeys as any)[
                    (feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any)[skillKey]
                      .level_of_confidence
                  ]
                }
              </SkillRowValue>
              <ProgressBar
                {...getConfidenceBarProps(
                  (confidenceKeys as any)[
                    (feSkillsData?.skillsFrontEnds?.data[0]?.attributes as any)[skillKey]
                      .level_of_confidence
                  ],
                )}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const renderContent = () => (
    <Card style={{ width: '100%', marginBottom: '1rem' }}>
      <Card.Body>
        <div className="d-flex flex-row justify-content-between">
          <h4>Technical Skills</h4>
          {authUser?.role.name === Roles.Manager && (
            <ManageButton
              variant="dark"
              onClick={() => {
                window.open(
                  !!feSkillsData?.skillsFrontEnds?.data[0]?.id
                    ? `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::skills-front-end.skills-front-end/${feSkillsData?.skillsFrontEnds?.data[0]?.id}`
                    : `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::skills-front-end.skills-front-end?page=1&pageSize=10&sort=id:ASC`,
                  '_blank',
                );
              }}>
              <FaCog /> Manage
            </ManageButton>
          )}
        </div>
        <CategoryHeader>General Front End</CategoryHeader>
        <Row>
          {renderCardProgress('HTML', 'html')}
          {renderCardProgress('CSS', 'css')}
          {renderCardProgress('JavaScript', 'javascript')}
          {renderCardProgress('JQuery', 'jquery')}
          {hasDataForSections(['html', 'css', 'javascript', 'jquery'])}
        </Row>
        <CategoryHeader>Javascript Web Frameworks </CategoryHeader>
        <Row>
          {renderCardProgress('ReactJS', 'reactjs')}
          {renderCardProgress('VueJS', 'vuejs')}
          {renderCardProgress('Angular', 'angular')}
          {hasDataForSections(['reactjs', 'vuejs', 'angular'])}
        </Row>
        <CategoryHeader>CMS/Ecommerce - Themes Designs</CategoryHeader>
        <Row>
          {renderCardProgress('Shopify', 'shopify')}
          {renderCardProgress('Wordpress', 'wordpress')}
          {hasDataForSections(['shopify', 'wordpress'])}
        </Row>
        <CategoryHeader>Mobile Development (Native & React Native) </CategoryHeader>
        <Row>
          {renderCardProgress('React Native', 'react_native')}
          {renderCardProgress(
            'Native Mobile Development (Android & iOS)',
            'native_mobile_development',
          )}
          {hasDataForSections(['react_native', 'native_mobile_development'])}
        </Row>
        <CategoryHeader>SSR React Frameworks </CategoryHeader>
        <Row>
          {renderCardProgress('GatsbyJS', 'gatsbyjs')}
          {renderCardProgress('NextJS', 'nextjs')}
          {hasDataForSections(['gatsbyjs', 'nextjs'])}
        </Row>
        <CategoryHeader>Headless CMS</CategoryHeader>
        <Row>
          {renderCardProgress('Directus (Headless CMS)', 'directus')}
          {renderCardProgress('Strapi (Headless CMS)', 'strapi')}
          {hasDataForSections(['directus', 'strapi'])}
        </Row>
        <CategoryHeader>Others</CategoryHeader>
        <Row>
          {renderCardProgress(
            'Google Analytics & Google Tag Manager',
            'google_analytics_and_tag_manager',
          )}
          {renderCardProgress('SEO', 'seo')}
          {renderCardProgress('Accessibility', 'accessibility')}
          {renderCardProgress('XR (AFrame, ThreeJS, Blender or Unity)', 'xr')}
          {renderCardProgress('BE Frameworks (Fullstack)', 'be_frameworks')}
          {renderCardProgress('Blockchains', 'blockchain')}
          {renderCardProgress('Machine Learning', 'machine_learning')}
          {hasDataForSections([
            'google_analytics_and_tag_manager',
            'seo',
            'accessibility',
            'xr',
            'be_frameworks',
            'blockchain',
            'machine_learning',
          ])}
        </Row>
      </Card.Body>
    </Card>
  );

  return feLoading ? <LoadingIndicator /> : renderContent();
};

export default FESkills;
