import styled from 'styled-components';
// eslint-disable-next-line import/no-named-as-default
import AppLogo from '../assets/images/logo.png';
import { FaHome, FaUsers } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route } from '../types/routes.d';
import useUserContext from '../contexts/UserContext';
import { useEffect, useState } from 'react';
import { useContainerDimensions } from '../hooks/useContainerDimensions';
// import { Roles } from '../types/roles.d';

const CustomContainer = styled(Container)`
  padding: 0px;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 30vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkBlue};
`;

const Content = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
`;

const LeftColumn = styled.div`
  width: 17.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RightColumn = styled.div`
  width: 82.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LeftTopContent = styled.div`
  width: 100%;
  height: 30vh;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const LeftBottomContent = styled.div`
  width: 100%;
  height: 70vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const TopWrapper = styled.div`
  ${({ theme }) => `
  width: 100%;
  height: ${theme.getValueHeight(80)}px;
  display: flex;
  flex-direction: column;
`}
`;

const ChildrenWrapper = styled.div`
  ${({ theme }) => `
  width: calc(100% - ${theme.getValueHeight(28)}px);
  height: calc(100% - ${theme.getValueHeight(132)}px);

  padding: ${theme.getValueHeight(16)}px ${theme.getValueHeight(24)}px;
  background-color: white;
  overflow-y: auto;
  box-shadow: 10px 10px 6px -8px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 10px 10px 6px -8px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 10px 10px 6px -8px rgba(0, 0, 0, 0.35);
`}
`;

const Logo = styled.img`
  ${({ theme }) => `
    width: ${theme.getValueHeight(140)}px;
    object-fit: contain;
    height: ${theme.getValueHeight(64)}px;
    margin-top: ${theme.getValueHeight(8)}px;
    margin-left: ${theme.getValueHeight(24)}px;
  `}
`;

const AuthorPlaceholder = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValueHeight(12)}px;
    width: ${theme.getValueHeight(64)}px;
    height: ${theme.getValueHeight(64)}px;
    font-size: ${theme.getValue(32)}px;
    color: ${theme.colors.white};
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
  `}
`;

const AuthorName = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValueHeight(8)}px;
    font-size: ${theme.getValueHeight(theme.isMobile ? 16 : 20)}px;
    width: auto;
    color: white;
    font-weight: 600;
  `}
`;

const AuthorTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.getValueHeight(theme.isMobile ? 12 : 14)}px;
    color: ${theme.colors.white};
    width: auto;
    font-weight: 600;
  `}
`;

const TopLink = styled.button`
  ${({ theme }) => `
  font-size: ${theme.getValueHeight(theme.isMobile ? 12 : 18)}px;
  color: ${theme.colors.white};
  width: auto;
  font-weight: 600;
  background: transparent;
  height: 100%;
  border: 0px;
  align-self: flex-end;
  margin-right: ${theme.getValueHeight(20)}px;
  `}
`;

const MenuOption = styled.div<{ selected?: boolean; isBig?: boolean }>`
  ${({ theme, selected, isBig }) => `
  font-size: ${theme.getValueHeight(14)}px;
  padding: ${theme.getValueHeight(!isBig ? 8 : 16)}px ${theme.getValueHeight(isBig ? 18 : 24)}px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: ${theme.colors.black};
  border-left: ${selected ? `${theme.getValueHeight(4)}px solid ${theme.colors.darkBlue}` : '0px'};
  background-color: ${selected ? theme.colors.highlightBlue : isBig ? 'white' : 'transparent'};
  ${isBig && 'border-bottom: 1px solid #dee2e6; border-right: 1px solid #dee2e6;'}

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: ${theme.colors.highlightBlue};
  }
  `}
`;

const InvalidDevice = styled.div`
  ${({ theme }) => `
    font-size: ${theme.getValue(26)}px;
    font-weight: 800;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: ${theme.getValue(16)}px;
    text-align: center;
    justify-content: center;
    background-color: ${theme.colors.darkBlue};
    color: ${theme.colors.white};
  `}
`;

const CustomAccordion = styled(Accordion)`
  width: 100%;

  .accordion-body {
    padding: 0px;
  }

  .header-text {
    margin-left: 0.5rem;
  }
`;

export const AdminLayout = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isInvalidDeviceScreen, setInvalidDeviceScreen] = useState(false);
  const { logout, authUser, authUserInfo, isMounted } = useUserContext();
  const { width, height } = useContainerDimensions();

  useEffect(() => {
    if (width / height >= 1.35 && height >= 550 && width >= 768) {
      setInvalidDeviceScreen(false);
    } else {
      setInvalidDeviceScreen(true);
    }
  }, [width, height]);

  useEffect(() => {
    if (!authUser && isMounted) {
      navigate(Route.AdminLogin);
    }
  }, [authUser, isMounted]);

  if (isInvalidDeviceScreen) {
    return (
      <InvalidDevice>
        Invalid Dimensions detected for this experience, please make sure you are not on a Mobile
        device and try again.
      </InvalidDevice>
    );
  }

  const getDefaultKeyMenu = () => {
    if (['/admin/dashboard'].includes(location.pathname as Route)) {
      return '-1';
    }
    if ([Route.AdminFeedbacks, Route.AdminSoftSkills].includes(location.pathname as Route)) {
      return '1';
    }
    if (
      [
        Route.AdminTeamsRoles,
        Route.AdminTeamsBadges,
        Route.AdminTeamsDocuments,
        Route.AdminTeamsMember,
        Route.AdminTeamsMembers,
        Route.AdminTeamsOrganigram,
      ].includes(location.pathname as Route)
    ) {
      return '0';
    }
    return '0';
  };

  return (
    <CustomContainer fluid>
      <TopRow></TopRow>
      <Content>
        <LeftColumn>
          <LeftTopContent>
            <TopWrapper>
              <Logo src={AppLogo} className="img img-responsive" />
            </TopWrapper>
            <AuthorPlaceholder>
              {authUserInfo?.usersPermissionsUser.data.attributes.full_name[0]}
            </AuthorPlaceholder>
            <AuthorName className="text-center">
              {authUserInfo?.usersPermissionsUser.data.attributes.full_name}
            </AuthorName>
            <AuthorTitle>
              {authUserInfo?.usersPermissionsUser.data.attributes.company_role}
            </AuthorTitle>
          </LeftTopContent>
          <LeftBottomContent>
            <CustomAccordion defaultActiveKey={getDefaultKeyMenu()}>
              <MenuOption
                onClick={() => navigate(Route.AdminDashboard)}
                isBig
                selected={Route.AdminDashboard === location.pathname}>
                <FaHome />
                Dashboard
              </MenuOption>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <FaUsers />
                  <div className="header-text">Teams</div>
                </Accordion.Header>
                <Accordion.Body>
                  <MenuOption
                    onClick={() => navigate(Route.AdminTeamsBadges)}
                    selected={Route.AdminTeamsBadges === location.pathname}>
                    Badges
                  </MenuOption>
                  <MenuOption
                    onClick={() => navigate(Route.AdminTeamsDocuments)}
                    selected={Route.AdminTeamsDocuments === location.pathname}>
                    Documents
                  </MenuOption>
                  <MenuOption
                    onClick={() => navigate(Route.AdminTeamsMembers)}
                    selected={Route.AdminTeamsMembers === location.pathname}>
                    Members
                  </MenuOption>
                  <MenuOption
                    onClick={() => navigate(Route.AdminTeamsOrganigram)}
                    selected={Route.AdminTeamsOrganigram === location.pathname}>
                    Organigram
                  </MenuOption>
                  <MenuOption
                    onClick={() => navigate(Route.AdminTeamsRoles)}
                    selected={Route.AdminTeamsRoles === location.pathname}>
                    Roles
                  </MenuOption>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <FaThList />
                  <div className="header-text">Misc</div>
                </Accordion.Header>
                <Accordion.Body>
                  <MenuOption
                    onClick={() => navigate(Route.AdminSoftSkills)}
                    selected={Route.AdminSoftSkills === location.pathname}>
                    Soft Skills
                  </MenuOption>
                  {authUser?.role.name === Roles.Manager && (
                    <MenuOption
                      onClick={() => navigate(Route.AdminFeedbacks)}
                      selected={Route.AdminFeedbacks === location.pathname}>
                      Feedbacks
                    </MenuOption>
                  )}
                </Accordion.Body>
              </Accordion.Item> */}
            </CustomAccordion>
          </LeftBottomContent>
        </LeftColumn>
        <RightColumn>
          <TopWrapper>
            <TopLink onClick={logout}>Sign Out</TopLink>
          </TopWrapper>
          <ChildrenWrapper>{children}</ChildrenWrapper>
        </RightColumn>
      </Content>
    </CustomContainer>
  );
};

export default AdminLayout;
