import { gql } from '@apollo/client';

export const GET_TEAMS = gql`
  query GetTeams {
    teams(sort: ["is_general"]) {
      data {
        attributes {
          name
          color {
            data {
              attributes {
                value
                name
              }
            }
          }
          slug
          with_newsletter
          is_general
        }
      }
    }
  }
`;
