/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FaTrash, FaCheck, FaEye } from 'react-icons/fa';
import { Row, Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import AdminLayout from '../../components/AdminLayout';
import { useEffect, useState } from 'react';
import moment from 'moment';

export const GET_FEEDBACKS = gql`
  query GetFeedbacks {
    feedbacks(sort: ["createdAt:desc"], filters: { status: { not: { eq: "Declined" } } }) {
      data {
        id
        attributes {
          subject
          message
          status
          email
          createdAt
        }
      }
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation UpdateFeedback($id: ID!, $status: ENUM_FEEDBACK_STATUS) {
    updateFeedback(id: $id, data: { status: $status }) {
      data {
        id
      }
    }
  }
`;

type Feedback = {
  id: string;
  attributes: {
    subject: string;
    message: string;
    status: string;
    email: string;
    createdAt: string;
  };
};

type GetFeedbacksType = {
  feedbacks: {
    data: Feedback[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const TableRow = styled.tr<{ isNew?: boolean }>`
  ${({ isNew }) => `
    background: ${isNew ? '#e8f4ff' : 'inherit'};
  `}
`;

function MyVerticallyCenteredModal({
  feedback,
  show,
  onHide,
}: {
  feedback: Feedback | undefined;
  show: boolean;
  onHide: () => void;
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{feedback?.attributes.subject}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{feedback?.attributes.email || 'Anonymously'}</h4>
        <p>{feedback?.attributes.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export const Feedbacks = () => {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback>();
  const [openModal, setOpenModal] = useState(false);
  const [refetchFeedbacks, setRefetchFeedbacks] = useState(0);

  const [updateFeedback] = useMutation(UPDATE_FEEDBACK);
  const [fetchTeams] = useLazyQuery<GetFeedbacksType>(GET_FEEDBACKS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setFeedbacks(data.feedbacks.data || []);
    },
  });

  const handleUpdateFeedback = async (id: string, status: string) => {
    try {
      await updateFeedback({
        variables: {
          id,
          status,
        },
      });
      setRefetchFeedbacks(new Date().getTime());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, [refetchFeedbacks]);

  return (
    <AdminLayout>
      <Container>
        <Title>Feedbacks</Title>
        <Row>
          <Card>
            <Table responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Sender</th>
                  <th>Message</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {feedbacks.map((feedback) => (
                  <TableRow key={feedback.id} isNew={feedback.attributes.status === 'Pending'}>
                    <td>
                      {feedback.attributes.status === 'Pending' && (
                        <h5>
                          <Badge bg="info" text="light">
                            New!
                          </Badge>
                        </h5>
                      )}
                    </td>
                    <td>{moment(feedback.attributes.createdAt).format('YYYY-MM-DD HH:mm A')}</td>
                    <td>{feedback.attributes.subject}</td>
                    <td>{feedback.attributes.email || 'Anonymously'}</td>
                    <td>{feedback.attributes.message.substring(0, 100)}</td>
                    <td>
                      {feedback.attributes.status === 'Pending' ? (
                        <ButtonGroup aria-label="Basic example">
                          <Button
                            onClick={() => {
                              setSelectedFeedback(feedback);
                              setOpenModal(true);
                            }}
                            variant="primary">
                            <FaEye />
                          </Button>
                          <Button
                            onClick={() => handleUpdateFeedback(feedback.id, 'Resolved')}
                            variant="success">
                            <FaCheck />
                          </Button>
                          <Button
                            onClick={() => handleUpdateFeedback(feedback.id, 'Declined')}
                            variant="danger">
                            <FaTrash />
                          </Button>
                        </ButtonGroup>
                      ) : (
                        <ButtonGroup aria-label="Basic example">
                          <Button
                            onClick={() => {
                              setSelectedFeedback(feedback);
                              setOpenModal(true);
                            }}
                            variant="primary">
                            <FaEye />
                          </Button>
                          <Button
                            onClick={() => handleUpdateFeedback(feedback.id, 'Declined')}
                            variant="danger">
                            <FaTrash />
                          </Button>
                        </ButtonGroup>
                      )}
                    </td>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </Card>
        </Row>
        <MyVerticallyCenteredModal
          show={openModal}
          onHide={() => setOpenModal(false)}
          feedback={selectedFeedback}
        />
      </Container>
    </AdminLayout>
  );
};

export default Feedbacks;
