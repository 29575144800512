/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { FaCog, FaFileAlt } from 'react-icons/fa';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { gql, useQuery } from '@apollo/client';
import AdminLayout from '../../components/AdminLayout';
import useTeamsContext from '../../contexts/TeamsContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import { sortBy } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import FESkills from '../../components/FESkills';
import { useEffect, useState } from 'react';
import { Route } from '../../types/routes.d';
import AccesibilityIcon from '../../assets/images/accesibilityIcon.png';
import { Roles } from '../../types/roles.d';
import useUserContext from '../../contexts/UserContext';
// import SoftSkills from '../../components/SoftSkills';

export const GET_MEMBERS = gql`
  query GetTeamMember($id: ID) {
    member(id: $id) {
      data {
        attributes {
          full_name
          badges {
            data {
              id
              attributes {
                name
                badge_icon {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          team_roles {
            data {
              id
              attributes {
                name
                organigram_level_position
                color {
                  data {
                    attributes {
                      value
                    }
                  }
                }
                team {
                  data {
                    id
                    attributes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetMembeAttachments($id: ID) {
    attachments(filters: { member: { id: { eq: $id } } }) {
      data {
        attributes {
          name
          type
          file {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

type GetMemberType = {
  member: {
    data: {
      id: string;
      attributes: {
        full_name: string;
        badges: {
          data: {
            id: string;
            attributes: {
              name: string;
              badge_icon?: {
                data: {
                  attributes: {
                    url: string;
                  };
                };
              };
            };
          }[];
        };
        team_roles: {
          data: {
            id: string;
            attributes: {
              name: string;
              organigram_level_position: number;
              team: {
                data: {
                  attributes: {
                    slug: string;
                    name: string;
                  };
                };
              };
              color: {
                data: {
                  attributes: {
                    value: string;
                  };
                };
              };
            };
          }[];
        };
      };
    };
  };
};

type GetAttachmentType = {
  attachments: {
    data: {
      attributes: {
        name: string;
        type: string;
        file: {
          data: {
            attributes: {
              url: string;
            };
          };
        };
      };
    }[];
  };
};

const Title = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;

    .badges-container {
      display: flex;
      flex-direction: row;
      margin-left: ${theme.getValue(16)}px;
    }
  `}
`;

const CustomRow = styled(Row)`
  & > * {
    width: auto;
  }
`;

const Circle = styled.div<{ color: string }>`
  display: inline-block;
  ${({ theme, color }) => `
    width: ${theme.getValue(16)}px;
    border-radius: ${theme.getValue(16)}px;
    height: ${theme.getValue(16)}px;
    margin-right: ${theme.getValue(16)}px;
    background: ${color};
  `}
`;

const BadgeIcon = styled.img`
  ${({ theme }) => `
  width: ${theme.getValue(36)}px;
  height: ${theme.getValue(36)}px;
  margin-bottom: ${theme.getValue(8)}px;
  object-fit: contain;
  object-position: center;
  `}
`;

const AttachmentRow = styled(Card.Text)`
  cursor: pointer;
`;

const ManageButton = styled(Button)`
  ${({ theme }) => `
   margin-right: 1rem;
   background-color: ${theme.colors.primary};
    border: 0px;
  `}
`;

export const TeamMember = () => {
  const { selectedTeam, teams: allTeams, setSelectedTeam } = useTeamsContext();
  const { authUser } = useUserContext();

  const navigate = useNavigate();
  const [teams, setTeams] = useState<any[]>([]);
  const { memberId } = useParams();

  const { data: memberData, loading: membersLoading } = useQuery<GetMemberType>(GET_MEMBERS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: memberId,
    },
  });

  const { data: attachmentsData, loading: attachmentLoading } = useQuery<GetAttachmentType>(
    GET_ATTACHMENTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: memberId,
      },
    },
  );

  useEffect(() => {
    const data: any = {};
    memberData?.member.data.attributes.team_roles.data.map((teamRole) => {
      data[teamRole.attributes.team.data.attributes.name] =
        teamRole.attributes.team.data.attributes;
    });
    setTeams(Object.values(data));
  }, [memberData]);

  useEffect(() => {
    if (
      allTeams.length &&
      teams.length &&
      !teams.some((team) => team.slug === selectedTeam?.slug)
    ) {
      setSelectedTeam(allTeams.find((t) => t.slug === teams[0].slug));
    }
  }, [selectedTeam, allTeams, teams]);

  const renderContent = () => (
    <>
      <CustomRow className="d-flex flex-row align-items-center justify-content-between">
        <div>
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate(Route.AdminDashboard)}>
              Dashboard
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate(Route.AdminTeamsMembers)}>
              Members
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{memberData?.member.data.attributes.full_name}</Breadcrumb.Item>
          </Breadcrumb>
          <Title>
            <span>{memberData?.member.data.attributes.full_name}</span>
            <div className="badges-container">
              {memberData?.member.data.attributes.badges.data.map((badge) => (
                <div key={badge.id}>
                  <OverlayTrigger
                    placement={'bottom'}
                    overlay={<Tooltip id={`tooltip-bottom`}>{badge.attributes.name}</Tooltip>}>
                    <BadgeIcon
                      src={badge.attributes.badge_icon?.data?.attributes.url || AccesibilityIcon}
                    />
                  </OverlayTrigger>
                </div>
              ))}
            </div>
          </Title>
        </div>
        <Row>
          <div className="d-flex flex-row">
            {authUser?.role.name === Roles.Manager && (
              <div className="d-flex flex-row justify-content-end">
                <ManageButton
                  variant="dark"
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_API_URL}/admin/content-manager/collectionType/api::member.member/${memberId}`,
                      '_blank',
                    );
                  }}>
                  <FaCog /> Manage
                </ManageButton>
              </div>
            )}
            <ButtonGroup className="mb-2">
              {teams.map((team) => (
                <Button
                  variant={selectedTeam?.slug === team.slug ? 'dark' : 'default'}
                  key={team.slug}
                  onClick={() => {
                    setSelectedTeam(allTeams.find((t) => t.slug === team.slug));
                  }}>
                  {team?.name}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </Row>
      </CustomRow>
      <Row>
        <Col md={3} className="mb-3">
          <Card>
            <Card.Body>
              <h5>Team Roles</h5>
              {sortBy(
                memberData?.member.data.attributes.team_roles.data,
                (element) => element.attributes.organigram_level_position,
              )
                .filter((el) => el.attributes.team.data.attributes.slug === selectedTeam?.slug)
                .map((teamRole) => (
                  <Card.Text key={teamRole.id}>
                    <Circle color={teamRole.attributes.color.data?.attributes?.value || 'black'} />
                    {teamRole.attributes.name}
                  </Card.Text>
                ))}
            </Card.Body>
          </Card>

          {!!attachmentsData?.attachments.data.length && (
            <Card className="mt-2">
              <Card.Body>
                {!!attachmentsData?.attachments.data.filter(
                  (d) => d.attributes.type === 'Evaluation',
                ).length && (
                  <>
                    <h5>Evaluations</h5>
                    {attachmentsData?.attachments.data
                      .filter((d) => d.attributes.type === 'Evaluation')
                      .map((att) => (
                        <AttachmentRow
                          onClick={() =>
                            window.open(att.attributes.file.data.attributes.url, '_blank')
                          }
                          key={att.attributes.name}>
                          <FaFileAlt /> {att.attributes.name}
                        </AttachmentRow>
                      ))}
                  </>
                )}

                {!!attachmentsData?.attachments.data.filter(
                  (d) => d.attributes.type !== 'Evaluation',
                ).length && (
                  <>
                    <h5>Others</h5>
                    {attachmentsData?.attachments.data
                      .filter((d) => d.attributes.type !== 'Evaluation')
                      .map((att) => (
                        <AttachmentRow
                          onClick={() =>
                            window.open(att.attributes.file.data.attributes.url, '_blank')
                          }
                          key={att.attributes.name}>
                          <FaFileAlt /> {att.attributes.name}
                        </AttachmentRow>
                      ))}
                  </>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col md={9}>
          {selectedTeam?.slug !== 'front-end' ? (
            <h2>TBD</h2>
          ) : (
            <>
              {/* <SoftSkills /> */}
              <FESkills />
            </>
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <AdminLayout>
      <Container>
        {membersLoading || attachmentLoading ? <LoadingIndicator /> : renderContent()}
      </Container>
    </AdminLayout>
  );
};

export default TeamMember;
