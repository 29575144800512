export type ColorsType = {
  white: string;
  black: string;
  darkBlue: string;
  highlightBlue: string;
  lightBlue: string;
  primary: string;
  gray: string;
};

export type FontsType = {
  southwestSans: string;
};

export const colors: ColorsType = {
  primary: '#FF5A03',
  white: '#ffffff',
  black: '#000',
  darkBlue: '#0B0B45',
  highlightBlue: '#D5EBFA',
  lightBlue: '#F0F9FF',
  gray: '#969696',
};

export const fonts: FontsType = {
  southwestSans: 'SouthwestSans, sans-serif',
};

export type BreakpointsType = {
  mobileS: string;
  mobileM: string;
  mobileL: string;
  tablet: string;
  laptop: string;
  laptopL: string;
  desktop: string;
  desktopL: string;
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const breakpoints: BreakpointsType = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors,
  fonts,
  breakpoints,
};
