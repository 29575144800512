/* eslint-disable import/no-named-as-default */
import Card from 'react-bootstrap/Card';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import useTeamsContext from '../../contexts/TeamsContext';
import FESkillsSearch from './FESkillsSearch/FESkillsSearch';

interface Props {
  show: boolean;
  onHide: () => void;
}

export const TeamsMemberSearchModal = ({ show, onHide }: Props) => {
  const { selectedTeam } = useTeamsContext();

  const renderContent = () => (
    <>
      <Row>
        <Col md={12}>
          {selectedTeam?.slug === 'front-end' ? (
            <FESkillsSearch />
          ) : (
            <Card style={{ width: '100%' }}>
              <Card.Body>TBD</Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Advanced Member Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
    </Modal>
  );
};

export default TeamsMemberSearchModal;
