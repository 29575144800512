/* eslint-disable import/no-named-as-default */
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import { gql, useQuery } from '@apollo/client';
import { Col, Row, Card } from 'react-bootstrap';
import AdminLayout from '../../components/AdminLayout';
import Color from 'color';

export const GET_FEEDBACKS = gql`
  query GetFeedbacks {
    feedbacks(filters: { status: { eq: "Pending" } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query GetTeams {
    teams {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers {
    members {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

const Title = styled.div`
  ${({ theme }) => `
    margin-top: ${theme.getValue(16)}px;
    font-size: ${theme.getValue(theme.isMobile ? 32 : 48)}px;
    margin-bottom: ${theme.getValue(24)}px;
    font-weight: 900;
  `}
`;

const CustomCard = styled(Card)<{ color: string }>`
  ${({ theme, color }) => `
    border: 0px;
    background-image: linear-gradient(to right bottom, ${Color(color).darken(0.4)}, ${color});
    padding: ${theme.getValue(16)}px ${theme.getValue(32)}px;
  `}
`;

const CardTitle = styled.div`
  ${({ theme }) => `
    font-size: ${theme.getValue(theme.isMobile ? 20 : 28)}px;
    color: ${theme.colors.white};
    font-weight: 800;
  `}
`;

const CardAmount = styled.div`
  ${({ theme }) => `
    font-size: ${theme.getValue(theme.isMobile ? 48 : 72)}px;
    margin-top: ${theme.getValue(-16)}px;
    color: ${theme.colors.white};
    text-align: right;
    font-weight: 800;
  `}
`;

export const Dashboard = () => {
  const { data: teamsData } = useQuery(GET_TEAMS);
  const { data: membersData } = useQuery(GET_MEMBERS);
  const { data: feedbacksData } = useQuery(GET_FEEDBACKS);

  return (
    <AdminLayout>
      <Container>
        <Title>Dashboard</Title>
        <Row>
          <Col md={6} lg={4} sm={12}>
            <CustomCard color="#3c40c6">
              <CardTitle>Teams</CardTitle>
              <CardAmount>{teamsData?.teams?.meta.pagination.total}</CardAmount>
            </CustomCard>
          </Col>
          <Col md={6} lg={4} sm={12}>
            <CustomCard color="#f53b57">
              <CardTitle>Members</CardTitle>
              <CardAmount>{membersData?.members?.meta.pagination.total}</CardAmount>
            </CustomCard>
          </Col>
          <Col md={6} lg={4} sm={12}>
            <CustomCard color="#0fbcf9">
              <CardTitle>Unread Feedbacks</CardTitle>
              <CardAmount>{feedbacksData?.feedbacks?.meta.pagination.total}</CardAmount>
            </CustomCard>
          </Col>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default Dashboard;
