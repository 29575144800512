import { useEffect, useState } from 'react';

export const useDeviceDetector = (): { isMobile: boolean } => {
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setContainerWidth(document.documentElement.clientWidth || document.body.clientWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();
  });

  const isMobile = containerWidth <= 767;

  return { isMobile };
};
