/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Team, GetTeamsType } from './types';
import { GET_TEAMS } from './queries';
import useUserContext from '../UserContext';

interface ContextProps {
  teams: Team[];
  setSelectedTeam: (v: Team | undefined) => void;
  selectedTeam: Team | undefined;
}

export const TeamsContext = React.createContext({} as ContextProps);

interface Props {
  children: React.ReactNode;
}

export const TeamsProvider: React.FC<Props> = ({ children }: Props) => {
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = React.useState<Team>();
  const { authUser } = useUserContext();

  const [fetchTeams] = useLazyQuery<GetTeamsType>(GET_TEAMS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTeams(data.teams.data.map((d) => d.attributes) || []);
    },
  });

  React.useEffect(() => {
    fetchTeams();
  }, [authUser]);
  return (
    <TeamsContext.Provider
      value={{
        teams,
        setSelectedTeam,
        selectedTeam,
      }}>
      {children}
    </TeamsContext.Provider>
  );
};

export default function useTeamsContext(): ContextProps {
  return React.useContext(TeamsContext);
}
