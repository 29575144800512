import styled from 'styled-components';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import AppLogo from '../assets/images/logo.png';
import { useEffect, useState } from 'react';
import useUserContext from '../contexts/UserContext';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Route } from '../types/routes.d';

export const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
    }
  }
`;

const Logo = styled.img`
  ${({ theme }) => `
    width: ${theme.isMobile ? 240 : 256}px;
    max-width: 500px;
    object-fit: contain;
    height: ${theme.isMobile ? 108 : 184}px;
  `}
`;

const CustomContainer = styled(Container)`
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(to right top, #000345, #1f002e, #20001b, #18000c, #000000);
`;

const CustomCard = styled(Card)`
  width: 95%;
  max-width: 800px;
  background-color: white;
  ${({ theme }) => `
    padding: ${theme.getValue(48)}px ${theme.getValue(theme.isMobile ? 8 : 32)}px;
  `}
`;

export const Login = () => {
  const [login] = useMutation(LOGIN);
  const navigate = useNavigate();
  const { setAuthToken, authUser, isMounted } = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const res = await login({
        variables: {
          identifier: email,
          password,
        },
      });
      setAuthToken(res.data.login.jwt);
      localStorage.setItem('token', res.data.login.jwt);
      navigate(Route.AdminDashboard);
    } catch (e) {
      toast.error('There was an error trying to sign in to your account.');
    }
  };

  useEffect(() => {
    if (authUser && isMounted) {
      navigate(Route.AdminDashboard);
    }
  }, [authUser, isMounted]);

  return (
    <CustomContainer fluid>
      <Logo src={AppLogo} className="img img-responsive" />
      <CustomCard>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Password"
              />
            </Form.Group>
            <Button
              disabled={!email || !password}
              variant="dark"
              type="button"
              onClick={handleLogin}>
              Submit
            </Button>
          </Form>
        </Card.Body>
      </CustomCard>
    </CustomContainer>
  );
};

export default Login;
