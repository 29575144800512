/* eslint-disable @typescript-eslint/indent */
import * as React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GetUserType, GetUserInfoType } from './types';
import { GET_CURRENT_USER, GET_CURRENT_USER_INFO } from './queries';
import { Route } from '../../types/routes.d';
import { useNavigate } from 'react-router-dom';

interface ContextProps {
  authUser: any;
  isMounted: boolean;
  authUserInfo: GetUserInfoType | undefined;
  setAuthUser: (v: any) => void;
  authToken: string;
  logout: () => void;
  setAuthToken: (v: string) => void;
}

export const UserContext = React.createContext({} as ContextProps);

interface Props {
  children: React.ReactNode;
}

export const UserProvider: React.FC<Props> = ({ children }: Props) => {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = React.useState<any>();
  const [authUserInfo, setAuthUserInfo] = React.useState<GetUserInfoType>();
  const [authToken, setAuthToken] = React.useState('');
  const [mounted, setMounted] = React.useState(false);

  const [fetchCurrentUser] = useLazyQuery<GetUserType>(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setAuthUser(data.me);
      setMounted(true);
    },
    onError: () => {
      setAuthToken('');
      setAuthUser(undefined);
      localStorage.removeItem('token');
      navigate(Route.AdminLogin);
      setMounted(true);
    },
  });

  const [fetchCurrentUserInfo] = useLazyQuery<GetUserInfoType>(GET_CURRENT_USER_INFO, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setAuthUserInfo(data);
    },
    onError: () => {
      setAuthUserInfo(undefined);
    },
  });

  React.useEffect(() => {
    if (authToken) fetchCurrentUser();
  }, [authToken]);

  React.useEffect(() => {
    if (authUser?.id) fetchCurrentUserInfo({ variables: { id: authUser?.id } });
  }, [authUser?.id]);

  React.useEffect(() => {
    const validate = async () => {
      const token = localStorage.getItem('token');
      if (token) setAuthToken(token);
      else setMounted(true);
    };
    validate();
  }, []);

  return (
    <UserContext.Provider
      value={{
        authToken,
        authUser,
        authUserInfo,
        isMounted: mounted,
        setAuthUser,
        setAuthToken,
        logout: () => {
          setAuthToken('');
          setAuthUser(undefined);
          setAuthUserInfo(undefined);
          localStorage.removeItem('token');
          navigate(Route.AdminLogin);
        },
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default function useUserContext(): ContextProps {
  return React.useContext(UserContext);
}
